import Component from '~/components/component';

export default class CardChrono extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
	}

	retrieveDOM() {
		this.dom.button = this.dom.component.querySelector('.button_more');
	}

	bind() {
		this.dom.button.addEventListener('click', () => {
			this.dom.component.classList.toggle('open');
		});
	}
}
