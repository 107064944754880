import Component from '~/components/component';

export default class HeroHome extends Component {
	constructor(...args) {
		super(...args);
	}

	/*
    init() {
    }
    bind() {
    }
    unbind() {
    }
    resize(width, height) {
    }
    */
}
