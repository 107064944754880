import Component from '~/components/component';

export default class BlocService extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.cards = this.dom.component.querySelectorAll('.card');
	}

	bind() {
		this.cards.forEach((card) => {
			card.addEventListener('mouseenter', () => {
				card.classList.toggle('card_hover');
				let video = card.querySelector('video');
				video.play();
			});

			card.addEventListener('mouseleave', () => {
				card.classList.toggle('card_hover');
				let video = card.querySelector('video');
				setTimeout(() => {
					video.pause();
				}, 100);
			});
		});
	}
}
