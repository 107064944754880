import Component from '~/components/component';
import { gsap } from 'gsap';
export default class Map extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.link = this.dom.component.querySelector('.link');
		this.map = this.dom.component.querySelector('img');
	}

	bind() {
		if (!this.link) return;
		this.link.addEventListener('mouseenter', () => {
			gsap.to(this.map, {
				scale: 1.1,
				duration: 0.4,
				ease: 'power2.easeInOut',
			});
		});

		this.link.addEventListener('mouseleave', () => {
			gsap.to(this.map, {
				scale: 1,
				duration: 0.4,
				ease: 'power2.easeInOut',
			});
		});
	}
}
