import Sniffer from 'snifferjs'
import Supports from './Supports'

// Class to detect browser / os / etc
class Detect {
  init (ua) {
    this.sniffer = Sniffer(ua)
    this.os = this.sniffer.os
    this.browser = this.sniffer.browser
    this.feature = this.sniffer.feature

    this.browserName = this.sniffer.browser.name

    this.isMobile = this.sniffer.features.mobile
    this.isDesktop = !this.sniffer.features.mobile

    this.addClasses()
    this.supports = new Supports()

    window.detectDebug = this;

    window.browserName = this.sniffer.browserName
  }

  addClasses () {
    const body = document.body
    body.classList.add(`os-${this.os.name}`)
    body.classList.add(`browser-${this.browserName}`)
    if (this.isMobile) body.classList.add('is-mobile')
    if (this.isDesktop) body.classList.add('is-desktop')
  }
}

export default new Detect()
