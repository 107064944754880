import Swiper, { Navigation, Mousewheel, Autoplay } from 'swiper';
import Component from '~/components/component';

export default class SliderPartenaires extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.dom.slide = this.dom.component.querySelectorAll('.slide');
		this.dom.wrapper = this.dom.component.querySelector('.swiper-container');
		this.dom.prevButton = this.dom.component.querySelector('.button.prev');
		this.dom.nextButton = this.dom.component.querySelector('.button.next');
	}

	onClickNavigation = () => {
		this.swiper.autoplay = false;
		this.swiper.params.speed = 300;
	};

	initSwiper() {
		this.swiper = new Swiper(this.dom.wrapper, {
			modules: [Autoplay, Navigation, Mousewheel],
			direction: 'horizontal',
			speed: 5000,
			loop: this.dom.slide.length >= 15 ? true : false,
			autoplay: this.dom.slide.length >= 15 ? { delay: 1 } : false,
			freeMode: true,
			mousewheel: { forceToAxis: true },
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},

			breakpoints: {
				0: {
					slidesPerView: 'auto',
					spaceBetween: 12,
				},
				768: {
					slidesPerView: 'auto',
					spaceBetween: 30,
				},
			},
		});

		this.dom.prevButton.addEventListener('click', this.onClickNavigation);
		this.dom.nextButton.addEventListener('click', this.onClickNavigation);
	}
}
