import Component from '~/components/component';

const media = window.matchMedia('(max-width: 1024px)');
export default class Header extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.openButton = this.dom.component.querySelectorAll(
			'.mobile-target-click',
		);
		this.backButton = this.dom.component.querySelectorAll('.button_back');
		this.burgerButton = this.dom.component.querySelector('.burger');
	}

	bind() {
		if (!media.matches) return;

		this.burgerButton.addEventListener('click', () => {
			this.dom.component.classList.toggle('header_open');
		});

		this.openButton.forEach((button) => {
			button.querySelector('.onglet_title').addEventListener('click', () => {
				button
					.querySelector('.onglet_child')
					.classList.toggle('onglet_child_open');
				this.dom.component.classList.toggle('header_child_open');
			});
		});

		this.backButton.forEach((button) => {
			button.addEventListener('click', () => {
				button.parentNode.parentNode.classList.toggle('onglet_child_open');
				this.dom.component.classList.toggle('header_child_open');
			});
		});
	}
}
