import Swiper, { Navigation, Mousewheel } from 'swiper';
import Component from '~/components/component';

export default class BlocChrono extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.dom.wrapper = this.dom.component.querySelector('.swiper-container');
		this.dom.prevButton = this.dom.component.querySelector('.button.prev');
		this.dom.nextButton = this.dom.component.querySelector('.button.next');
	}

	initSwiper() {
		const swiper = new Swiper(this.dom.wrapper, {
			modules: [Navigation, Mousewheel],
			direction: 'horizontal',
			freeMode: true,
			mousewheel: { forceToAxis: true },
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
			breakpoints: {
				0: {
					slidesPerView: 1,
					spaceBetween: 15,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
				1228: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
			},
		});
	}
}
