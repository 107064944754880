import Component from '~/components/component';

export default class HeroHubNews extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.scrollButton = this.dom.component.querySelector('.scroll-down');
	}

	bind() {
		const amount = this.dom.component.getBoundingClientRect().height;
		this.scrollButton.addEventListener('click', () => {
			window.scrollTo({
				top: amount - 180, // 180 = navbar height + a bit of marge
				left: 0,
				behavior: 'smooth',
			});
		});
	}
}
