import Component from '~/components/component';
import { gsap } from 'gsap';

export default class CustomCursor extends Component {
	position = { x: 0, y: 0 };
	scale = 0;
	radius = 140;
	timingCursor = 0.4;
	timingText = 0.5;
	videoPlaying = false;
	hovering = false;

	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.cursor = this.dom.component;
		this.textSee = this.dom.component.querySelector('.see');
		this.textPlay = this.dom.component.querySelector('.play');
		this.cardsSee = document.querySelectorAll('[data-cursor-see]');
		this.cardsPlay = document.querySelectorAll('[data-cursor-play]');
	}

	updatePosition({ clientX, clientY }) {
		this.position = { x: clientX, y: clientY };
		this.updateCursor();
	}

	updateCursor() {
		gsap.to(this.cursor, {
			x: this.position.x,
			y: this.position.y,
			scale: this.scale,
			duration: this.timingCursor,
			ease: 'power2',
		});
	}

	hideCursor(type) {
		if (type == 'see') this.toggleText(this.textSee, 0);
		if (type == 'play') this.toggleText(this.textPlay, 0);

		this.scale = 0;
		this.updateCursor();
	}

	showCursor(type) {
		if (this.videoPlaying && type == 'play') return;
		if (type == 'see') this.toggleText(this.textSee, 1);
		if (type == 'play') this.toggleText(this.textPlay, 1);

		this.scale = 1;
		this.updateCursor();
	}

	toggleText = (el, value) => {
		gsap.to(el, {
			opacity: value,
			duration: this.timingText,
			ease: 'power2',
		});
	};

	toggleCursorPlay = () => {
		if (!this.hovering) return;
		if (!this.videoPlaying) {
			this.hideCursor('play');
			this.videoPlaying = true;
			return;
		}

		if (this.videoPlaying) {
			this.videoPlaying = false;
			this.showCursor('play');
			return;
		}
	};

	showVideoControls = (card) => {
		const video = card.querySelector('video');
		video.controls = true;
	};

	attachCardEvent(cards, type) {
		if (cards.length >= 1) {
			cards.forEach((card) => {
				card.addEventListener('mouseenter', () => {
					if (type == 'play') this.hovering = true;
					this.showCursor(type);
				});

				card.addEventListener('mouseleave', () => {
					if (type == 'play') this.hovering = false;
					this.hideCursor(type);
				});

				if (type === 'play')
					card.addEventListener('click', () => {
						this.toggleCursorPlay();
						this.showVideoControls(card);
					});
			});
		}
	}

	bind() {
		this.radius = this.cursor.getBoundingClientRect().width;
		document.addEventListener('mousemove', (e) => this.updatePosition(e));
		document.addEventListener('keyup', (event) => {
			if (event.code === 'Space') this.toggleCursorPlay();
		});

		this.attachCardEvent(this.cardsSee, 'see');
		this.attachCardEvent(this.cardsPlay, 'play');
	}
}
