import Stage from './Stage'
import Emitter from './Emitter'

import keycode from 'keycode'
import { isArray, isObject } from 'utils/common'

const config = {
  layouts: {
    qwerty: {
      up: [38, 87],
      down: [40, 83],
      left: [37, 65],
      right: [39, 68]
    },
    azerty: {
      up: [38, 90],
      down: [40, 83],
      left: [37, 81],
      right: [39, 68]
    }
  }
}

// Manage all the global keyboard inputs
class Keyboard {
  constructor () {
    this._binded = false
    this._isQwerty = true // Detect qwerty here

    this.pressedKeys = {}

    this.bind()
  }

  // ====== public
  bind () {
    if (this._binded) {
      return
    }

    window.addEventListener('keydown', this._handleKeyDown, false)
    window.addEventListener('keyup', this._handleKeyUp, false)
    Emitter.on(Stage.BLURRED, this._onWindowBlur)

    this._binded = true
  }

  unbind () {
    if (!this._binded) {
      return
    }

    window.removeEventListener('keydown', this._handleKeyDown, false)
    window.removeEventListener('keyup', this._handleKeyUp, false)
    Emitter.off(Stage.BLURRED, this._onWindowBlur)

    this._binded = false
  }

  isDown (name) {
    let isDown = false

    const keyCodes = this._getKeyLayout(name)
    for (let i = 0, l = keyCodes.length; i < l; i++) {
      const keyCode = keyCodes[i]
      if (this.pressedKeys[keyCode]) {
        isDown = true
        break
      }
    }
    return isDown
  }

  addDown (cb) {
    Emitter.on(Keyboard.DOWNED, cb)
  }

  removeDown (cb) {
    Emitter.off(Keyboard.DOWNED, cb)
  }

  addUp (cb) {
    Emitter.on(Keyboard.UPPED, cb)
  }

  removeUp (cb) {
    Emitter.off(Keyboard.UPPED, cb)
  }

  // ====== private
  _resetInputs () {
    this.pressedKeys = {}
  }

  _getKeyLayout (val) {
    if (typeof val === 'number') {
      return this._keyCodeToKeyName(val)
    } else if (typeof val === 'string') {
      return this._keyNameToKeyCodes(val)
    } else if (isObject(val) && typeof val.keyCode !== 'undefined') {
      return this._keyCodeToKeyName(val.keyCode)
    }
  }

  _keyCodeToKeyName (keyCode) {
    let name = keycode(keyCode)

    const layout = this._isQwerty ? 'qwerty' : 'azerty'
    const keyCodesLayout = config.layouts[layout]
    for (const keyName in keyCodesLayout) {
      const keyCodes = keyCodesLayout[keyName]
      if (keyCodes.indexOf(keyCode) !== -1) {
        name = keyName
        break
      }
    }

    return name
  }

  _keyNameToKeyCodes (keyName) {
    let keyCodes = keycode(keyName)

    const layout = this._isQwerty ? 'qwerty' : 'azerty'
    const multipleKeyCodes = config.layouts[layout][keyName]
    if (multipleKeyCodes) {
      keyCodes = multipleKeyCodes
    }

    return !isArray(keyCodes) ? [keyCodes] : keyCodes
  }

  // --- handlers
  @autobind
  _handleKeyDown (event) {
    // event.prevenDefault()
    // event.stopPropagation()

    this.downNow = new Date().getTime()
    this.pressedKeys[event.keyCode] = true

    const layoutKey = this._getKeyLayout(event)
    const originalKey = keycode(event)

    Emitter.emit(Keyboard.DOWNED, event, layoutKey, originalKey)
  }

  @autobind
  _handleKeyUp (event) {
    // event.prevenDefault()
    // event.stopPropagation()

    const now = new Date().getTime()
    this.pressDuration = now - this.downNow
    delete this.pressedKeys[event.keyCode]

    const layoutKey = this._getKeyLayout(event)
    const originalKey = keycode(event)

    Emitter.emit(Keyboard.UPPED, event, layoutKey, originalKey)
  }

  // ------ listeners
  @autobind
  _onWindowBlur () {
    this._resetInputs()
  }

  @autobind
  _onVisibilityChange (toggle) {
    if (toggle === false) {
      this._resetInputs()
    }
  }

  static UPPED = 'keyboard_upped'
  static DOWNED = 'keyboard_downed'
}

export default new Keyboard()
