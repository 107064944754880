import Component from '~/components/component';
import Choices from 'choices.js';

export default class ContactForm extends Component {
	_actionRegisterForm = 'agp_send_email_register_ajax';

	constructor(...args) {
		super(...args);
		this.retrieveDOM();
	}

	init() {
		super.init();

		this.fields = [
			this.dom.nom,
			this.dom.prenom,
			this.dom.email,
			this.dom.tel,
			this.dom.entreprise,
			this.dom.job,
			this.dom.message,
			this.dom.accept,
			this.dom.selectOne,
			this.dom.selectTwo,
		];
	}

	retrieveDOM() {
		// FORM DOM
		this.dom.successMessage = this.dom.component.querySelector('.success');
		this.dom.errorMessageField =
			this.dom.component.querySelector('.error_field');
		this.dom.errorMessageGlobal =
			this.dom.component.querySelector('.error_global');

		this.dom.nom = this.dom.component.querySelector('#nom');
		this.dom.prenom = this.dom.component.querySelector('#prenom');
		this.dom.email = this.dom.component.querySelector('#email');
		this.dom.tel = this.dom.component.querySelector('#tel');
		this.dom.job = this.dom.component.querySelector('#job');
		this.dom.entreprise = this.dom.component.querySelector('#entreprise');
		this.dom.message = this.dom.component.querySelector('#message');
		this.dom.accept = this.dom.component.querySelector('#accept');

		this.dom.selectOne = this.dom.component.querySelector(
			'.contact_select_one',
		);
		this.dom.selectTwo = this.dom.component.querySelector(
			'.contact_select_two',
		);

		this.dom.loader = this.dom.component.querySelector('#contact-loader');
		this.dom.submit = this.dom.component.querySelector('#submit');
	}

	initSelectOptions() {
		if (this.dom.selectOne) {
			let optionOne = this.dom.selectOne.getAttribute('data-option-one');
			let optionTwo = this.dom.selectOne.getAttribute('data-option-two');

			this.choicesOne = new Choices(this.dom.selectOne, {
				searchEnabled: false,
				itemSelectText: '',
			});

			this.choicesOne.setChoices(
				[
					{ value: optionOne.toLowerCase(), label: optionOne },
					{ value: optionTwo.toLowerCase(), label: optionTwo },
				],
				'value',
				'label',
				true,
			);
		}

		if (this.dom.selectTwo) {
			let options = this.dom.selectTwo.querySelectorAll('option[data-option]');

			this.choicesTwo = new Choices(this.dom.selectTwo, {
				searchEnabled: false,
				itemSelectText: '',
			});

			this.choicesTwo.setChoices(
				Array.from(options).map((option) => {
					const v = option.getAttribute('data-option').toLowerCase();
					return {
						value: v,
						label: v.charAt(0).toUpperCase() + v.slice(1),
					};
				}),
				'value',
				'label',
				true,
			);
		}
	}

	validateEmail = (email) => {
		const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (regex.test(email)) return true;
		return false;
	};

	showError = (fields) => {
		fields.forEach((field) => {
			field.classList.add('input-error');

			if (field.nodeName == 'SELECT') {
				field.parentNode.classList.add('input-error');
			}
		});
		this.dom.errorMessageField.classList.add('visible');
	};

	hideError = (fields) => {
		fields.forEach((field) => {
			field.classList.remove('input-error');

			if (field.nodeName == 'SELECT') {
				field.parentNode.classList.remove('input-error');
			}
		});
		this.dom.errorMessageField.classList.remove('visible');
	};

	handleForm = (e) => {
		e.preventDefault();

		const fieldsError = [];
		const fieldsOk = [];

		this.fields.forEach((field) => {
			if (!field.value) {
				fieldsError.push(field);
				return;
			}

			if (field.nodeName == 'SELECT' && field.value == '') {
				fieldsError.push(field);
				return;
			}

			if (field.name == 'email') {
				if (!this.validateEmail(field.value)) {
					fieldsError.push(field);
					return;
				}
			}

			if (field.name == 'tel') {
				if (field.value.length < 5) {
					fieldsError.push(field);
					return;
				}
			}

			if (field.name == 'accept') {
				if (!field.checked == 1) {
					fieldsError.push(field);
					return;
				}
			}

			fieldsOk.push(field);
		});

		if (fieldsOk.length > 0) this.hideError(fieldsOk);
		if (fieldsError.length > 0) this.showError(fieldsError);

		if (fieldsError.length <= 0) {
			const data = new FormData();

			for (let i in fieldsOk) {
				data.append(fieldsOk[i].id, fieldsOk[i].value);
			}

			// Request
			this.dom.loader.classList.add('visible-loader');
			this._request(data);
		}
	};

	bind() {
		this.initSelectOptions();
		this.dom.submit.addEventListener('click', this.handleForm);
	}

	/**
	 * Request "Form Contact Send"
	 * @param data (FormData)
	 */
	_request(data) {
		const xhr = new XMLHttpRequest();

		xhr.open(
			'POST',
			'/wp/wp-admin/admin-ajax.php?action=' + this._actionRegisterForm,
			true,
		);

		xhr.onreadystatechange = function () {
			if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				this._response(JSON.parse(xhr.response));
			}
		}.bind(this);
		xhr.send(data);
	}

	/**
	 * Response to the request to send the form
	 * @param response Ajax
	 */
	_response(response) {
		this.dom.loader.classList.remove('visible-loader');

		console.log(response);

		if (response.success) {
			this.dom.successMessage.classList.add('visible');
			this.dom.errorMessageGlobal.classList.remove('visible');
			this.dom.component.reset();
		} else {
			console.log('error');
			this.dom.errorMessageGlobal.classList.add('visible');
		}
	}
}
