import Component from '~/components/component';
import { gsap } from 'gsap';

export default class PageTransition extends Component {
	_classes = {
		blockDark: 'js-page-transition-block-dark',
		blockDarkBorder: 'js-page-transition-block-dark-border',
		blockGrey: 'js-page-transition-block-grey',
		blockDarkOff: 'js-page-transition-block-dark-off',
		noAjax: 'no-ajax',
	};

	_urlOrigin =
		window.location.origin ||
		window.location.protocol + '//' + window.location.hostname;
	_nextLocationHref = null;

	constructor(...args) {
		super(...args);
		this._retrieveDOM();
	}

	_retrieveDOM() {
		this.dom.sections = {
			dark: this.dom.component.querySelector(`.${this._classes.blockDark}`),
			darkBorder: this.dom.component.querySelector(
				`.${this._classes.blockDarkBorder}`,
			),
			grey: this.dom.component.querySelector(`.${this._classes.blockGrey}`),
			darkOff: this.dom.component.querySelector(
				`.${this._classes.blockDarkOff}`,
			),
		};
	}

	_resetTimelines() {
		this._resetPageTl = gsap.timeline({ paused: true });

		this._resetPageTl.to(
			[
				this.dom.sections.dark,
				this.dom.sections.darkBorder,
				this.dom.sections.grey,
			],
			{
				y: '100%',
				duration: 0,
			},
		);

		this._resetPageTl.to(this.dom.sections.darkOff, {
			y: '100%',
			duration: 0,
		});

		this.dom.component.style.display = 'none';
		this._resetPageTl.play();
	}

	_initTimelines() {
		this._showRevealPageTl = gsap.timeline({
			paused: true,
			onComplete: () => {
				this._resetTimelines();
				this.showingPage.bind(this);
			},
		});

		this._showRevealPageTl.to(
			[
				this.dom.sections.darkBorder,
				this.dom.sections.dark,
				this.dom.sections.grey,
			],
			{
				y: '-102%',
				duration: 1.3,
				ease: 'Expo.easeOut',
				stagger: 0.22,
			},
		);

		this._showRevealPageTl.play();

		// LEAVING PAGE
		this._showLeavingPageTl = gsap.timeline({
			paused: true,
			onComplete: this.leavingPage.bind(this),
		});

		this._showLeavingPageTl.to(this.dom.sections.darkOff, {
			y: 0,
			duration: 1.1,
			ease: 'Expo.easeOut',
		});
	}

	showingPage() {
		this.dom.component.style.display = 'none';
	}

	leavingPage() {}

	startLeavingPage(href = null) {
		this.dom.component.style.display = 'block';
		this.dom.sections.darkOff.style.opacity = 1;

		this._showLeavingPageTl.play();

		setTimeout(() => {
			window.location = this._nextLocationHref;
		}, this._showLeavingPageTl.duration() * 1000);
	}

	onWindowPageShow(event) {
		if (event.persisted) {
			document.querySelector('html').classList.add('reloading');
			window.location.reload();
		}
	}

	_linkClickedHandler(event) {
		let href = event.delegateTarget.getAttribute('href');
		let target = event.delegateTarget.getAttribute('target');

		// Prevent self clicked url
		if (href == document.location.href) {
			event.preventDefault();
			return null;
		}

		if (
			href.includes(this._urlOrigin) &&
			!event.delegateTarget.classList.contains(this._classes.noAjax) &&
			href.indexOf('#') == -1 &&
			href.indexOf('mailto:') == -1 &&
			href.indexOf('tel:') == -1 &&
			target != '_blank'
		) {
			event.preventDefault();
			event.stopImmediatePropagation();

			this._nextLocationHref = href;

			this.startLeavingPage();

			return null; // prevent user navigation away until animation's finished
		}
	}

	bind() {
		super.bind();

		delegate(
			document.body,
			'[href]',
			'click',
			this._linkClickedHandler.bind(this),
			false,
		);

		// Trick history cache btn and page-transitions issue
		window.addEventListener('pageshow', this.onWindowPageShow.bind(this));
		window.addEventListener('load', this._initTimelines.bind(this));
	}
}
