import Raf from 'quark-raf'
import Stage from 'helpers/Stage'
import Store from 'helpers/Store'
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);
import { randomFloat } from 'utils/math'
import Detect from 'helpers/Detect'

class TextAnimation {
  constructor (el, type, opts = {}) {
    if (!el) return;
    this.el = el;
    this.type = type;
    this.opts = opts;
    this._isAnimated = false;
    this._timeouts = [];
    this.resize();
    this.bind();


    gsap.set(el, { opacity: 0 });
    this.baseContent = el.innerHTML;
  }

  bind() {
    this.rafData = Raf.add(this.update, 30);
  }
  
  destroy() {
    if(this._tl) this._tl.kill();
    if(this._timeouts) this._timeouts.forEach(to=> clearTimeout(to));
    Raf.remove(this.update);
  }

  delayedCall(cb, time) {
    const to = setTimeout(cb, time);
    this._timeouts.push(to);
    return to;
  }

  animateIn() {
    if (this._isAnimated) return;
    this._isAnimated = true;
    Raf.remove(this.update);

    if (this.opts.callback) this.opts.callback();

    switch (this.type) {
      case 'title':
        this.titleAnim()
        break;
      case 'shuffle':
        this.shuffleAnim()
        break;
      case 'fade':
        this.fadeAnim()
        break;
      default: 
        this.titleAnim()
        break;
    }
  }

  titleAnim() {
    this.splitted = new SplitText(this.el, { type: 'lines'});
    this._tl = gsap.timeline();
    let delay = this.opts.delay || 0;

    let yFrom = Detect.isMobile ? 30 : 50;
    this._tl.set(this.splitted.lines, { y: yFrom,  opacity: 0 }, 0.);
    this._tl.set(this.el, { opacity: 1 }, delay);
    this._tl.to(this.splitted.lines, { y: 0, opacity: 1, duration: .7, stagger: .14, ease: 'cubic.out', onComplete: _ => {
      this.splitted.revert();
    }}, delay);
  }

  shuffleAnim() {
    let splitted = new SplitText(this.el, { type: 'chars' });

    let tl = gsap.timeline({ onComplete: ()=>{
        // splitted.revert();
    }});

    this._tl = tl;

    let delay = this.opts.delay || 0;

    tl
      .set(splitted.chars, { fontFamily: 'glyph', letterSpacing: 5, opacity: 1 }, 0.)
      .set(this.el, { opacity: 1 }, delay)

    for (let i = 0, l = splitted.chars.length; i < l; i++) {
        let char = splitted.chars[i];
        let delay1 = randomFloat(0.2, .4) + delay;
        let delay2 = randomFloat(0.2, 1.);
        tl.to(char, { opacity: 0, duration: 0.1, onComplete: () => {
            char.style.fontFamily = 'Larsseit';
            char.style.letterSpacing = null;
            gsap.to(char, { opacity: 1, duration: .4 })
        } }, delay1);
    }
  }

  fadeAnim() {
    gsap.set(this.el, { opacity: 0.01 });

    let tl = gsap.timeline({ onComplete: ()=>{
    }});

    this._tl = tl;

    const duration = this.opts.duration || 1;
    const delay = this.opts.delay || .1;

    tl
        .to(this.el, { opacity: 1, duration, ease: 'cubic.out' }, delay)
  }

  resize() {
    if (this._isAnimated || !this.el) return;

    this.bbox = this.el.getBoundingClientRect();
    this.offsetY = Store.scrollTop;

    this.top = this.bbox.y + Store.scrollTop - Stage.height
    this.bottom = this.bbox.y + Store.scrollTop - Stage.height + this.bbox.height
  }

  @autobind
  update(delta, time) {
    if (Store.scrollTop > this.top + 50 && !this._isAnimated) {
      this.animateIn();
    }
  }
}

// STATIC
TextAnimation.getShuffleTl = (el, delay = 0) => {
  let tl = gsap.timeline({ paused: true });
  let splitted = new SplitText(el, { type: 'chars' });
  gsap.set(el, { opacity: 0 });
  tl
    .set(splitted.chars, { fontFamily: 'glyph', textTransform: 'uppercase', letterSpacing: 5, opacity: 1 }, 0.001)
    .set(el, { opacity: 1 }, delay)

  for (let i = 0, l = splitted.chars.length; i < l; i++) {
      let char = splitted.chars[i];
      let delay1 = randomFloat(0.2, .4) + delay;
      let delay2 = randomFloat(0.2, 1.);
      tl.to(char, { opacity: 0, duration: 0.1, onComplete: () => {
          char.style.fontFamily = 'Larsseit';
          char.style.letterSpacing = null;
          char.style.textTransform = 'none';
          gsap.to(char, { opacity: 1, duration: .4 })
      } }, delay1);
  }

  return tl;
}

export default TextAnimation
