export const map = {
	components: {
        fixedAnchors: require('components/FixedAnchors/FixedAnchors'),
        linkSmallArrowLeft: require('components/LinkSmallArrowLeft/LinkSmallArrowLeft'),
        pageTransition: require('components/PageTransition/PageTransition'),
        customCursor: require('components/CustomCursor/CustomCursor'),
        langSelect: require('components/LangSelect/LangSelect'),
        contactForm: require('components/ContactForm/ContactForm'),
        blocTeam: require('components/BlocTeam/BlocTeam'),
        cardChrono: require('components/CardChrono/CardChrono'),
        blocChrono: require('components/BlocChrono/BlocChrono'),
        blocValeurs: require('components/BlocValeurs/BlocValeurs'),
        cardCrossNav: require('components/CardCrossNav/CardCrossNav'),
        heroAbout: require('components/HeroAbout/HeroAbout'),
        jobList: require('components/JobList/JobList'),
        heroJoinUs: require('components/HeroJoinUs/HeroJoinUs'),
		newsList: require('components/NewsList/NewsList'),
		blocFrontNews: require('components/BlocFrontNews/BlocFrontNews'),
		heroHubNews: require('components/HeroHubNews/HeroHubNews'),
		cardNews: require('components/CardNews/CardNews'),
		moreActu: require('components/MoreActu/MoreActu'),
		linkRoundedBorderArrowSmall: require('components/LinkRoundedBorderArrowSmall/LinkRoundedBorderArrowSmall'),
		blocSocial: require('components/BlocSocial/BlocSocial'),
		socialShare: require('components/SocialShare/SocialShare'),
		heroNews: require('components/HeroNews/HeroNews'),
		crossNav: require('components/CrossNav/CrossNav'),
		blocIntroduction: require('components/BlocIntroduction/BlocIntroduction'),
		heroHubServices: require('components/HeroHubServices/HeroHubServices'),
		blocMoreServices: require('components/BlocMoreServices/BlocMoreServices'),
		heroService: require('components/HeroService/HeroService'),
		homeSliderTestimony: require('components/HomeSliderTestimony/HomeSliderTestimony'),
		arrowBorderGradient: require('components/ArrowBorderGradient/ArrowBorderGradient'),
		blocService: require('components/BlocService/BlocService'),
		linkRoundedBorderArrow: require('components/LinkRoundedBorderArrow/LinkRoundedBorderArrow'),
		heroHome: require('components/HeroHome/HeroHome'),
		filterTaxo: require('components/FilterTaxo/FilterTaxo'),
		map: require('components/Map/Map'),
		pagination: require('components/Pagination/Pagination'),
		backgroundSideBorder: require('components/BackgroundSideBorder/BackgroundSideBorder'),
		heroSimpleTitle: require('components/HeroSimpleTitle/HeroSimpleTitle'),
		cardReference: require('components/CardReference/CardReference'),
		linkRoundedBorder: require('components/LinkRoundedBorder/LinkRoundedBorder'),
		blocMoreReferences: require('components/BlocMoreReferences/BlocMoreReferences'),
		blocContact: require('components/BlocContact/BlocContact'),
		sliderNavigation: require('components/SliderNavigation/SliderNavigation'),
		sliderTestimony: require('components/SliderTestimony/SliderTestimony'),
		sliderPartenaires: require('components/SliderPartenaires/SliderPartenaires'),
		blocKeyFigures: require('components/BlocKeyFigures/BlocKeyFigures'),
		linkArrowBorder: require('components/LinkArrowBorder/LinkArrowBorder'),
		blocTitleTextLink: require('components/BlocTitleTextLink/BlocTitleTextLink'),
		blocImageText: require('components/BlocImageText/BlocImageText'),
		blocVideoBig: require('components/BlocVideoBig/BlocVideoBig'),
		blocImageGrid: require('components/BlocImageGrid/BlocImageGrid'),
		blocImageBig: require('components/BlocImageBig/BlocImageBig'),
		blocTitleContentRight: require('components/BlocTitleContentRight/BlocTitleContentRight'),
		flexibleContent: require('components/FlexibleContent/FlexibleContent'),
		blocCaracteristique: require('components/BlocCaracteristique/BlocCaracteristique'),
		heroRef: require('components/HeroRef/HeroRef'),
		footer: require('components/Footer/Footer'),
		header: require('components/Header/Header'),
	},
	pages: {
        legals: {
            legals: require('pages/legals/legals'),
        },
        about: {
            about: require('pages/About/About'),
        },
		joinus: {
			joinus: require('pages/joinUs/joinUs'),
		},
		notfound: {
			notfound: require('pages/notFound/notFound-index'),
		},
		hubnews: {
			hubnews: require('pages/hubNews/hubNews-index'),
		},
		news: {
			news: require('pages/news/news-index'),
		},
		services: {
			services: require('pages/services/services-index'),
		},
		hubservices: {
			hubservices: require('pages/hubServices/hubServices-index'),
		},
		hubreferences: {
			hubreferences: require('pages/hubReferences/hubReferences-index'),
		},
		homepage: {
			index: require('pages/homepage/homepage-index'),
		},
		contact: {
			index: require('pages/contact/contact-index'),
		},
		references: {
			index: require('pages/references/references-index'),
		},
	},
};
