import Swiper, { Navigation, Mousewheel, EffectFade, Autoplay } from 'swiper';
import Component from '~/components/component';

export default class HomeSliderTestimony extends Component {
	constructor(...args) {
		super(...args);
		this.retrieveDOM();
		this.initSwiper();
	}

	retrieveDOM() {
		this.dom.wrapper = this.dom.component.querySelector('.slider');
		this.dom.wrapperRef = this.dom.component.querySelector('.slider-ref');
		this.dom.prevButton = this.dom.component.querySelector('.button.prev');
		this.dom.nextButton = this.dom.component.querySelector('.button.next');
	}

	initSwiper() {
		const swiper = new Swiper(this.dom.wrapper, {
			modules: [Autoplay, Navigation, Mousewheel, EffectFade],
			direction: 'horizontal',
			mousewheel: { forceToAxis: true },
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
			speed: 600,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			loop: true,
			autoplay: true,
			slidesPerView: 1,
			spaceBetween: 0,
		});

		const swiperRef = new Swiper(this.dom.wrapperRef, {
			modules: [Autoplay, Navigation, Mousewheel, EffectFade],
			direction: 'horizontal',
			mousewheel: { forceToAxis: true },
			navigation: {
				prevEl: this.dom.prevButton,
				nextEl: this.dom.nextButton,
			},
			speed: 600,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			loop: true,
			autoplay: true,
			slidesPerView: 1,
			spaceBetween: 0,
		});
	}
}
