import Keyboard from 'helpers/Keyboard'
import Tweakpane from 'tweakpane'

import { getQuery } from 'utils/common'

class GUI {
  constructor () {
    this._autoOpen = getQuery('debug')
    this.container = document.createElement('div')
    this.container.classList.add('debug')
    document.body.appendChild(this.container)

    if (this._autoOpen) this.container.classList.add('is-visible')

    this._bind()
  }

  create (name) {
    if (window.pane) {
      this.destroy()
    }

    this.name = name
    
    if (!getQuery('pane') && !getQuery('debug')) return;
    
    window.pane = new Tweakpane({
      container: this.container,
      title: `---- ${name} ----`
    })

    return window.pane
    /* eslint-enable */
  }

  destroy (name) {
    if (name && name !== this.name) return
    if (this.pane) {
      window.pane.dispose()
      window.pane = null
    }
  }

  export () {
    if (!window.pane) return
    const config = window.pane.exportPreset()
    if (!this.exportPanel) {
      this.exportPanel = document.createElement('textarea')
      this.container.appendChild(this.exportPanel)
      this.exportPanel.style.position = 'fixed'
      this.exportPanel.style.width = '200px'
      this.exportPanel.style.height = '100px'
      this.exportPanel.style.right = '5px'
      this.exportPanel.style.bottom = '5px'
      this.exportPanel.style.background = '#444'
      this.exportPanel.style.color = '#fff'
      this.exportPanel.style.border = '5px solid #40bfc1'
    }
    this.exportPanel.value = JSON.stringify(config)
    return config
  }

  addVec (folder, vec, opts) {
    if (vec instanceof THREE.Vector2) {
      const obj = { vec }
      folder.addInput(obj, 'vec', { ...opts, label: opts.label })
    } else {
      ['x', 'y', 'z', 'w'].forEach((axis) => {
        if (typeof vec[axis] !== 'undefined') folder.addInput(vec, axis, { ...opts, label: `${opts.label} ${axis}` })
      })
    }
  }

  parseMaterial (folder, material, whitelist, blacklist) {

  }

  // ====== private
  _bind () {
    Keyboard.addUp(this._onKeyUp)
  }

  _unbind () {
    Keyboard.removeUp(this._onKeyUp)
  }

  _toggle () {
    if (this.container) this.container.classList.toggle('is-visible')
  }

  // --- listeners
  @autobind
  _onKeyUp (event, layoutKey) {
    if (!Keyboard.isDown('shift')) return

    if (layoutKey === 'g') {
      this._toggle()
    }
  }

  // --- getters / setters
  get pane () {
    return window.pane
  }
}

const gui = new GUI()
window.pane = null
window.gui = gui
export default gui
