import Component from '~/components/component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const media = window.matchMedia('(max-width: 1024px)');
export default class FilterTaxo extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.stopWrapper = document.querySelector('.filter-mobile-stop');
		this.openButton = this.dom.component.querySelector('.mobile_button');
		this.closeButton = this.dom.component.querySelector('.cross');
	}

	bind() {
		if (!media.matches) return;

		if (this.stopWrapper) {
			gsap.fromTo(
				this.openButton,
				{ opacity: 1, pointerEvents: 'all', userSelect: 'auto' },
				{
					duration: 0.3,
					opacity: 0,
					pointerEvents: 'none',
					userSelect: 'none',
					ease: 'power3',
					scrollTrigger: {
						trigger: this.stopWrapper,
						toggleActions: 'play none none reverse',
					},
				},
			);
		}

		this.closeButton.addEventListener('click', () => {
			this.dom.component.classList.toggle('filter_open');
		});

		this.openButton.addEventListener('click', () => {
			this.dom.component.classList.toggle('filter_open');
		});
	}
}
