import Component from '~/components/component';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class FixedAnchors extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.anchors = this.dom.component;
		this.stopWrapper = document.querySelector('.markers_anchors');
		this.links = this.dom.component.querySelectorAll('.link_anchor');
	}

	bind() {
		if (this.stopWrapper) {
			gsap.fromTo(
				this.anchors,
				{ y: '-100%', pointerEvents: 'none', userSelect: 'none' },
				{
					duration: 0.5,
					y: 0,
					pointerEvents: 'all',
					userSelect: 'auto',
					ease: 'Expo.easeInOut',
					scrollTrigger: {
						trigger: this.stopWrapper,
						start: 'top top',
						end: 'top bottom',
						toggleActions: 'play none none reverse',
					},
				},
			);
		}

		if (this.links) {
			this.links.forEach((link) => {
				const target = link.getAttribute('href');
				const el = document.querySelector(target);

				if (el) {
					const indicator = link.querySelector('.indicator');
					const arrow = link.querySelector('svg');

					gsap.fromTo(
						indicator,
						{ background: '#232323' },
						{
							duration: 0.3,
							background: '#fff',
							ease: 'power2',
							scrollTrigger: {
								trigger: el,
								start: 'top 80%',
								end: 'bottom 20%',
								toggleActions: 'play reverse play reverse',
								onEnterBack: () => {
									gsap.to(arrow, { duration: 0.3, ease: 'power2', rotate: 90 });
								},
								onLeave: () => {
									gsap.to(arrow, {
										duration: 0.4,
										ease: 'power2',
										rotate: -90,
									});
								},
							},
						},
					);
				}
			});
		}
	}
}
