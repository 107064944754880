import Page from '~/pages/page';

export default class About extends Page {
    constructor(...args) {
        super(...args);

        this._retrieveDOM();
        this._initTls();
    }

    /**
     * init() {}
     * bind() {}
     * unbind() {}
     * resize(width, height) {}
     * _destroy() {}
     */
}
