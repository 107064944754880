import { CONFIG } from '../config-project';
import States from '../helpers/states';
import Store from '../helpers/Store';
// import objectFitPolyfill from 'objectFitPolyfill';
// import RafManager from '../helpers/raf-manager';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger, SplitText);

// import Scrollbar from 'smooth-scrollbar';

// import SmoothScrollMagic from '../helpers/smooth-scroll-magic';

// import Scroll from '../helpers/scroll';
// import WebGLCanvas from '../webgl/webgl-canvas';
// import testFS from '../webgl/shaders/test.frag';

import Component from '../components/component';

export const PageEvent = {
	NEXT: '__pe_next',
	PAGE_DESTROYED: '__pe_page_destroyed',
};

export default class Page extends Component {
	_scrollValue = {
		current: null,
		prev: null,
	};

	constructor(page, components) {
		super();

		this.dom = {
			component: page,
			page,
			main: this._find('main'),
			scrollable: this._find('.scrollable'),
			container: this._find('.container'),
			scrollSections: this._findAll('.js-scroll-section'),
		};

		this.Components = components;

		if (Store.Scrollbar) Store.Scrollbar.locked = false;

		// if (CONFIG.IS_SMOOTH_SCROLL) {
		// 	this.dom.main.classList.add('is-smooth-scroll');
		// 	this.Scrollbar = Scrollbar.init(this.dom.main);

		// 	// this.SmoothScrollMagic = new SmoothScrollMagic(this.Scrollbar, this.dom.scrollSections);
		// }

		// this.scroll = new Scroll({
		//     direction: 'vertical',
		//     native: false,
		//     noscrollbar: false,
		//     listener: this.dom.page,
		//     section: this.dom.scrollable,
		//     sections: this.dom.scrollSections,
		//     ease: 0.1,
		//     vs: {
		//         preventTouch: true,
		//         passive: States.passive,
		//         sensibility: 1
		//     }
		// });

		// this.webGLCanvas = new WebGLCanvas({
		//     fragmentShader: testFS,
		//     uniforms: {
		//         uTexture: { type: 'sampler2D', value: this._find('img') },
		//         uTime: { type: '1f', value: 0.0 },
		//         uSpeed: { type: '1f', value: 1.0 }
		//     }
		// });
		// this.webGLCanvas.textures['uTexture'].clamp();

		bindAll(this, ['_update', '_scrollHandler', '_next', '_destroy']);

		this._showTl = gsap.timeline({ paused: true });
		this._hideTl = gsap.timeline({
			paused: true,
			onStart: () => {
				this.dom.page.style.position = 'absolute';
				this.dom.page.style.top = -getWindowPosition() + 'px';

				// Reset scroll
				document.scrollingElement.scrollTop =
					document.scrollingElement.scrollLeft =
					document.body.scrollTop =
					document.body.scrollLeft =
					document.documentElement.scrollTop =
					document.documentElement.scrollLeft =
					window.scrollTop =
					window.scrollLeft =
						0;
			},
			onComplete: this._destroy,
		});
	}

	_retrieveDOM() {}

	_initTls() {
		this._showTl.from(this.dom.page, { autoAlpha: 0, duration: 0 }, 0);

		this._hideTl.to(this.dom.page, { autoAlpha: 0, duration: 0 }, 0).add(() => {
			this._next();
		}, 0);
	}

	init() {
		console.log('init page');
		// if (this.scroll)
		//     this.scroll.init();

		this._getAndBindAnimatedTitle('data-animated-title');
		this._getAndBindAnimatedTitleDelay('data-animated-title-delay');
		this._getAndBindAnimatedBorder('data-animated-border');
	}

	bind() {
		// RafManager.on(this._update);
		// if (this.scroll)
		//     this.scroll.on(this._scrollHandler);

		['_scroll'].forEach((fn) => (this[fn] = this[fn].bind(this)));

		window.addEventListener('scroll', this._scroll, { passive: true });
	}

	unbind() {
		// RafManager.off(this._update);
		// if (this.scroll)
		//     this.scroll.off(this._scrollHandler);

		window.removeEventListener('scroll', this._scroll);
	}

	mediaLoadedHandler() {
		console.log('PAGE.JS : mediaLoadedHandler');
		this.mediaLoaded = true;
	}

	show() {
		this._showTl.play();
	}

	hide() {
		this._showTl.kill();
		this._hideTl.play();
	}

	_update(delta, time) {
		// if (this.webGLCanvas) {
		//     this.webGLCanvas.setUniform('uTime', time);
		//     this.webGLCanvas.render();
		// }
	}

	_scrollHandler(currentScroll) {}

	_scroll() {
		this._scrollValue.current = window.scrollY;

		// Example
		// if( this.Components.hasOwnProperty( 'Menu' ) ) {
		//     this.Components[ 'Menu' ]._scroll( this._scrollValue.current );
		// }
	}

	resize(width = States.width, height = States.height) {
		// if (this.scroll)
		//     this.scroll.resize(width, height);
		// if (this.webGLCanvas)
		//     this.webGLCanvas.resize(width, height);
	}

	_next() {
		this.emit(PageEvent.NEXT);
	}

	_destroy() {
		// if (this.scroll)
		//     this.scroll.destroy();

		// if (this.webGLCanvas)
		//     this.webGLCanvas.destroy();

		this._next();
		this.emit(PageEvent.PAGE_DESTROYED);
	}

	_getPersitance() {
		return false;
	}

	_getAndBindAnimatedTitle(data_name) {
		const titles = this.dom.page.querySelectorAll(`[${data_name}]`);

		if (titles) {
			titles.forEach((title) => {
				// Splitting two times to have a wrapper for each line with `overflow: hidden`
				let splitText = new SplitText(title, {
					tag: 'span',
					type: 'lines',
					linesClass: 'animated-title-line',
				});

				let splitTextWrapper = new SplitText(title, {
					tag: 'span',
					type: 'lines',
					linesClass: 'animated-title-line-wrapper',
				});

				gsap.from(splitText.lines, {
					duration: 1,
					opacity: 0,
					y: 100,
					ease: 'power4',
					scrollTrigger: {
						trigger: title,
						start: '150% bottom',
						end: 'bottom top',
					},
				});
			});
		}
	}

	_getAndBindAnimatedTitleDelay(data_name) {
		const titles = this.dom.page.querySelectorAll(`[${data_name}]`);

		if (titles) {
			titles.forEach((title) => {
				// Splitting two times to have a wrapper for each line with `overflow: hidden`
				let splitText = new SplitText(title, {
					tag: 'span',
					type: 'lines',
					linesClass: 'animated-title-line',
				});

				let splitTextWrapper = new SplitText(title, {
					tag: 'span',
					type: 'lines',
					linesClass: 'animated-title-line-wrapper',
				});

				gsap.from(splitText.lines, {
					duration: 1,
					opacity: 0,
					y: 100,
					delay: 1.3,
					ease: 'power4',
					scrollTrigger: {
						trigger: title,
						start: '150% bottom',
						end: 'bottom top',
					},
				});
			});
		}
	}

	_getAndBindAnimatedBorder(data_name) {
		const borders = this.dom.page.querySelectorAll(`[${data_name}]`);

		if (borders) {
			borders.forEach((border) => {
				gsap.from(border, {
					duration: 1.5,
					delay: 0.1,
					scaleX: 0,
					transformOrigin: '0% 0%',
					ease: 'power4',
					scrollTrigger: {
						trigger: border,
						start: '150% bottom',
						end: 'bottom top',
					},
				});
			});
		}
	}
}
