
class Supports {
  constructor () {
    this._webglContext = this._getWebglContext()
  }

  // ------ getters / setters
  get gamepad () {
    return window.navigator.getGamepads !== undefined
  }

  get canvas () {
    var elem = document.createElement('canvas')
    return !!(elem.getContext && elem.getContext('2d'))
  }

  get webgl () {
    return {
      supported: this._webglSupported(),
      extensions: this._webglExtensions(),
      textureFormat: this._webglTextureFormat()
    }
  }

  get blob () {
    return !!window.Blob
  }

  get worker () {
    return (typeof (Worker) !== 'undefined')
  }

  // ====== private
  _getWebglContext () {
    return document.createElement('canvas').getContext('webgl') ||
      document.createElement('canvas').getContext('experimental-webgl')
  }

  _webglSupported () {
    return !!this._webglContext
  }

  _getWebglExtension (gl, name) {
    const prefixes = ['', 'WEBKIT_', 'MOZ_']
    let extension = null

    for (const i in prefixes) {
      extension = gl.getExtension(prefixes[i] + name)
      if (extension) break
    }

    return extension
  }

  _webglExtensions () {
    const gl = this._webglContext
    if (!gl) {
      return null
    }

    return {
      dxt: this._getWebglExtension(gl, 'WEBGL_compressed_texture_s3tc')
    }
  }

  _webglTextureFormat () {
    const supportedExtensions = this._webglExtensions()

    let supportedFormat = 'uncompressed'
    if (supportedExtensions && supportedExtensions.dxt && supportedExtensions.dxt.COMPRESSED_RGB_S3TC_DXT1_EXT) {
      supportedFormat = 'dxt'
    }

    return { format: supportedFormat, compressed: (supportedFormat !== 'uncompressed') }
  }

  get webglContext () {
    return this._webglContext
  }
}

export default Supports
