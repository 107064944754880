import Component from '~/components/component';
import Dropdown from '../../helpers/dropdown';

export default class LangSelect extends Component {
	constructor(...args) {
		super(...args);
	}

	init() {
		const dropdown = new Dropdown({ dom: this.dom.component });
	}
}
