import { gsap } from 'gsap';
import Component from '~/components/component';

export default class BlocContact extends Component {
	constructor(...args) {
		super(...args);
		this._retrieveDom();
	}

	_retrieveDom() {
		this.magnetZone = this.dom.component.querySelector('#magnet_zone');
		this.magnetContent = this.dom.component.querySelector('#magnet');
	}

	parallaxIt(e, el, intensity = 0.1) {
		var sizes = this.magnetZone.getBoundingClientRect();
		var posX = e.pageX - sizes.left;
		var posY = e.pageY - sizes.top;
		var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		gsap.to(el, {
			x: (posX - sizes.width / 2) * intensity,
			y: (posY - sizes.height / 2 - scrollTop) * intensity,
			ease: 'power1',
			duration: 0.6,
		});
	}

	bind() {
		this.magnetZone.addEventListener('mousemove', (e) => {
			this.parallaxIt(e, this.magnetContent);
		});

		this.magnetZone.addEventListener('mouseleave', (e) => {
			gsap.to(this.magnetContent, {
				scale: 1,
				x: 0,
				y: 0,
				ease: 'power3',
				duration: 0.6,
			});
		});
	}
}
