import Page from '~/pages/page';

export default class HubReferencesIndex extends Page {
	constructor(...args) {
		super(...args);

		this._retrieveDOM();
		this._initTls();
		this.shouldScrollDown();
	}

	shouldScrollDown() {
		const search = window.location.search;

		if (search.includes('?')) {
			setTimeout(() => {
				this.dom.component
					.querySelector('.content_wrapper')
					.scrollIntoView({ behavior: 'smooth' });
			}, 1000);
		}
	}

	/**
	 * init() {}
	 * bind() {}
	 * unbind() {}
	 * resize(width, height) {}
	 * _destroy() {}
	 */
}
